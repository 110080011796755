<template>
  <div>

    <template v-if="!isPilotClient">

      <template v-if="hasRightsHotline">
        <v-list-item link :title="$t('menus.hotline')"
                     :href="'/plannet/module/ficheNavette/' + (hasNewHotlineTicket ? 'listeFicheLiaison' : '')"
                     target="_blank">
          <v-list-item-icon class="d-flex justify-center">
            <planete-icon color="white">fiche-liaison</planete-icon>
            <v-badge
                v-if="hasNewHotlineTicket"
                color="warning"
                left
                offset-x="10"
                class="elevation-4"
                overlap
                dot
            ></v-badge>
          </v-list-item-icon>
          <v-list-item-content link>
            <v-list-item-title class="white--text">{{ $t('menus.hotline') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

    </template>
    <template v-else>

      <template v-if="hasRightsHotline">
        <template v-if="isBetaMenu" >
          <v-badge
              class="float-left"
              style="z-index: 10000;"
              color="info"
              content="Beta !"
              offset-x="-8"
              offset-y="10"
              :dot="mini"
          ></v-badge>
        </template>
        <template v-if="!isBetaMenu && isNewMenu" >
          <v-badge
              class="float-left"
              style="z-index: 10000;"
              color="info"
              :dot="mini"
              :content="$t('actions.isNew')"
              offset-x="-8"
              offset-y="10"
          ></v-badge>
        </template>
        <div :class="{'mt-2': isNewMenu}"></div>
        <v-list-group v-if="isMaster" color="white">
          <template v-slot:activator>
            <v-list-item-icon class="d-flex justify-center">
              <planete-icon color="white">fiche-liaison</planete-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">Call center</v-list-item-title>
          </template>
          <v-list-item
            linkmessagerie-list--item--content
            :title="$t('menus.hotline')"
            :to="{ name: 'Hotline' }"
          >
            <v-list-item-icon class="d-flex justify-center">
              <planete-icon color="white">suivi-en-ligne</planete-icon>
            </v-list-item-icon>
            <v-list-item-content link>
              <v-list-item-title class="white--text">{{
                $t("menus.hotline")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link title="Tags messagerie" :to="{ name: 'HotlineRights' }">
            <v-list-item-icon class="d-flex justify-center">
              <planete-icon color="white">parametrage</planete-icon>
            </v-list-item-icon>
            <v-list-item-content link>
              <v-list-item-title class="white--text"
                >{{ $t("menus.hotlineRights")}}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <!-- icon  -->
          
          <!-- <menu-dashboard></menu-dashboard> -->
        </v-list-group>
      </template>
      <v-list-item
        link
        :title="$t('menus.hotline')"
        :to="{ name: 'Hotline' }"
        v-if="!isMaster && hasRightsHotline"
      >
        <v-list-item-icon class="d-flex justify-center">
          <planete-icon color="white">suivi-en-ligne</planete-icon>
        </v-list-item-icon>
        <v-list-item-content link>
          <v-list-item-title class="white--text">
            {{ $t("menus.hotline") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>


<script>
import PlaneteOnlineService from '@/Services/PlaneteOnlineService';
import { SVSHotline } from '@/Services/SupportVisionService';
import moment from 'moment';
import Vuex from 'vuex';

export default {
  name: 'MenuHotline',
  props: {
    mini: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MenuDashboard: () => import('@/Components/Commons/Interface/Menus/MenuDashboard'),
  },
  computed: {
    ...Vuex.mapState(['hotline']),
    hostname: () => location.hostname,
    isNewMenu: () => {
      let stopDate = moment('2022-06-17T21:59:59Z');
      // let stopDate = moment("2022-05-12T11:02:00Z")
      return (
        moment()
          .utc()
          .diff(stopDate, 'minutes') < 0
      ); // Date à partir de laquelle on considèrera que le menu n'est plus "nouveau"
    },
    isBetaMenu: () => {
      let stopDate = moment('2022-05-17T21:59:59');
      // let stopDate = moment("2022-05-12T11:00:00Z")
      return (
        moment()
          .utc()
          .diff(stopDate, 'minutes') < 0
      ); // Date à partir de laquelle on considèrera que le menu n'est plus "nouveau"
    },
    hasNewHotlineTicket() {
      return (this.hotline || []).length;
    },
  },
  data() {
    return {
      hasRightsHotline: false,
      isPilotClient: false,
      isMaster: false,
    };
  },
  methods: {
    async requestRightsFicheLiaison() {
      this.hasRightsHotline =
        (await PlaneteOnlineService.getRightsFicheLiaison()) == '1';
    },
  },
  async created() {
    await this.requestRightsFicheLiaison();
    this.isPilotClient = await SVSHotline.hasAccessToHotline(this.getDns());
    this.isMaster = this.isMasterBase();
  },
};
</script>

<style scoped></style>
